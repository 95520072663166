import React from "react";

const Footer = () => {
  return (
    <footer>
      {/* <div className="page-bg"> */}
        <div className="container">
          <div className="fooeter-row">
            {/* <div className="fooeter-col">
              <a href="#" className="footer-logo">
                <img src="assets/images/PO-LOGO.png" alt="" />
              </a>
            </div> */}
            <div className="fooeter-col">
              <ul className="social-nav">
                <li>
                  <a href="#" target="_blank">
                    <svg
                      id="twitter-_154_"
                      className="social-icons-style"
                      data-name="twitter-[#154]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50.351"
                      height="45.352"
                      viewBox="0 0 50.351 45.352"
                    >
                      <g id="Page-1" transform="translate(0 0)">
                        <g id="Dribbble-Light-Preview" transform="translate(0)">
                          <g id="icons">
                            <path
                              id="twitter-_154_2"
                              data-name="twitter-[#154]"
                              d="M18.263,7397.28c17.113,0,26.474-13.959,26.474-26.065,0-.4,0-.791-.027-1.185a18.727,18.727,0,0,0,4.642-4.744,18.758,18.758,0,0,1-5.343,1.444,9.229,9.229,0,0,0,4.091-5.067,18.9,18.9,0,0,1-5.909,2.223,9.42,9.42,0,0,0-13.166-.4,9.088,9.088,0,0,0-2.691,8.755,26.573,26.573,0,0,1-19.177-9.57,9.079,9.079,0,0,0,2.882,12.224,9.343,9.343,0,0,1-4.225-1.143v.114a9.2,9.2,0,0,0,7.465,8.979,9.387,9.387,0,0,1-4.2.158,9.3,9.3,0,0,0,8.692,6.362A18.854,18.854,0,0,1,4,7393.175a26.644,26.644,0,0,0,14.263,4.11"
                              transform="translate(-4 -7360.999)"
                              fill="#fff"
                              fill-rule="evenodd"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <svg
                      id="instagram-logo-fill"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50.351"
                      height="45.352"
                      viewBox="0 0 45.351 45.352"
                    >
                      <path
                        id="Path_2368"
                        data-name="Path 2368"
                        d="M0,0H45.351V45.351H0Z"
                        fill="none"
                      />
                      <circle
                        id="Ellipse_2485"
                        data-name="Ellipse 2485"
                        cx="5.5"
                        cy="5.5"
                        r="5.5"
                        transform="translate(17.171 17)"
                        fill="#fff"
                      />
                      <path
                        id="Path_2369"
                        data-name="Path 2369"
                        d="M53.51,28H37.921A9.932,9.932,0,0,0,28,37.921V53.51a9.932,9.932,0,0,0,9.921,9.921H53.51a9.932,9.932,0,0,0,9.921-9.921V37.921A9.932,9.932,0,0,0,53.51,28ZM45.715,54.219a8.5,8.5,0,1,1,8.5-8.5A8.5,8.5,0,0,1,45.715,54.219Zm9.212-15.59A2.126,2.126,0,1,1,57.053,36.5,2.126,2.126,0,0,1,54.927,38.629Z"
                        transform="translate(-23.04 -23.04)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    target="_blank"
                  >
                    <svg
                      id="discord-logo-fill"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50.351"
                      height="45.352"
                      viewBox="0 0 45.351 45.352"
                    >
                      <path
                        id="Path_2370"
                        data-name="Path 2370"
                        d="M0,0H45.351V45.351H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_2371"
                        data-name="Path 2371"
                        d="M50.427,59.6,44.408,39.533a2.826,2.826,0,0,0-1.636-1.808l-.1.029.1-.034a32.924,32.924,0,0,0-6.118-1.826,1.417,1.417,0,0,0-.553,2.78q1.192.237,2.336.557a1.413,1.413,0,0,1-.79,2.671,33.456,33.456,0,0,0-16.522-.032,1.416,1.416,0,0,1-.986-2.635l.006,0q1.143-.32,2.338-.558a1.417,1.417,0,1,0-.553-2.78,32.93,32.93,0,0,0-6.118,1.826,2.827,2.827,0,0,0-1.637,1.808L8.159,59.6a2.85,2.85,0,0,0,.866,2.963c.169.147.342.291.518.434L9.555,63a27.814,27.814,0,0,0,10.9,5.153,1.417,1.417,0,1,0,.672-2.754,28.474,28.474,0,0,1-5.551-1.961h.012A1.417,1.417,0,0,1,17.109,61.1a30.316,30.316,0,0,0,12.184,2.429A30.331,30.331,0,0,0,41.48,61.1,1.417,1.417,0,0,1,43,63.443h.012A28.475,28.475,0,0,1,37.46,65.4a1.417,1.417,0,1,0,.672,2.754A27.814,27.814,0,0,0,49.031,63l.012-.011c.176-.143.349-.287.518-.434a2.85,2.85,0,0,0,.866-2.963Zm-26.8-2.446a2.126,2.126,0,1,1,2.126-2.126A2.126,2.126,0,0,1,23.624,57.149Zm11.338,0a2.126,2.126,0,1,1,2.126-2.126A2.126,2.126,0,0,1,34.962,57.149Z"
                        transform="translate(-6.617 -29.513)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="social-icons-style"
                      width="40"
                      height="45.352"
                      viewBox="0 0 40 45.352"
                    >
                      <path
                        id="Icon_simple-tiktok"
                        data-name="Icon simple-tiktok"
                        d="M18.8.03C20.76,0,22.71.015,24.66,0a9.343,9.343,0,0,0,2.625,6.255,10.578,10.578,0,0,0,6.36,2.685v6.045a16.055,16.055,0,0,1-6.3-1.455,18.533,18.533,0,0,1-2.43-1.4c-.015,4.38.015,8.76-.03,13.125a11.457,11.457,0,0,1-2.025,5.91A11.175,11.175,0,0,1,14,35.985a10.937,10.937,0,0,1-6.12-1.545A11.311,11.311,0,0,1,2.4,25.875c-.03-.75-.045-1.5-.015-2.235a11.292,11.292,0,0,1,13.1-10.02c.03,2.22-.06,4.44-.06,6.66a5.147,5.147,0,0,0-6.57,3.18,5.95,5.95,0,0,0-.21,2.415,5.105,5.105,0,0,0,5.25,4.305,5.039,5.039,0,0,0,4.155-2.415,3.461,3.461,0,0,0,.615-1.59c.15-2.685.09-5.355.1-8.04C18.78,12.09,18.75,6.06,18.8.03Z"
                        transform="translate(-2.369)"
                        fill="#fff"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <p>© 2022</p>
          </div>
        </div>
      {/* </div> */}
    </footer>
  );
};

export default Footer;
