//Create a store boilerplate

import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from './reducers'; //because it's index.js we can bring in the folder
require("dotenv").config();


const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
)
export default store

