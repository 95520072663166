import React, { useEffect, useState }  from "react";

const Header = () => {
    const [status, setStatus] = useState(false)
    const [isInstalledMetamask, setInstalledMetamask] = useState(false)
    useEffect(()=>{
        if (typeof window.ethereum !== "undefined") {
           window.ethereum._metamask.isUnlocked().then(status =>{
               if(status){
                //alert("yes it is");
                setStatus(true);
            }
            else{
                 window.ethereum.request({ method: "eth_requestAccounts" });
                //alert("no it's not");
                setStatus(false);
            }
           })
            
            
            setInstalledMetamask(true);
            window.ethereum.on('accountsChanged', function (data){
                if(data.length!=0){
              setStatus(true);
            }
            else{
                setStatus(false);
            }
            
            window.location.reload()
            
        });
            
        } 
        
    },[])
    return (
        <header className="header">
        <div className="container">
            <div className="top-header">
            <div className="logo">
                <a href="/"><img src="assets/images/banner-img.png" alt="" /></a>
                </div>
                <div className="wallet-box">
                    {
                        (status && isInstalledMetamask) ? <><span className="wallet-icon"></span>Wallet Connected </> : (!isInstalledMetamask) ? <><span className="wallet-icon notconnect"></span>Wallet not installed , <a
                        href="https://metamask.io/download.html"
                        target="_blank"
                        // class="btn btn-banner"
                      >
                        {" "}
                        Click here to install {" "}
                      </a> </> : <><span className="wallet-icon notconnect"></span>Wallet Not Connected </>
                    }
                    
                </div>
                <div className="top-nav">
                    <div id="menu">&#x2261; Menu</div>
                    <nav>
                        <ul>
                            <li><a href="/#home-sec" className="active">HOME</a></li>
                            {/* <li><a href="/#about-sec">About Us</a></li> */}
                            <li><a href="/#gallery-sec">GALLERY</a></li>
                            {/*<li><a href="/#road-map-sec">ROADMAP</a></li>*/}
                            <li><a href="/#faq-sec">FAQ</a></li>
                            <li><a href="/#teams">TEAM</a></li>                                        
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    
    );
};

export default Header;
