
import React, { useEffect, useState } from "react";
import Header from '../header/Header';
import Footer from '../footer/Footer';
let Web3 = require('web3');
let abi = require('./abi.json');
const presaleTokenNo = 3;
const generalsaleTokenNo = 15;
const already_minted = 262;
let free_tokens_count = 100;
let total_with_free = already_minted+free_tokens_count;
const total_coins = 5000;
const networkVersion = 1;

const MintNFT = () => {



    const [metamask, isMetamask] = useState(null);
    const [coinNumners, setcoinNumners] = useState("");
    const [coinValue, setcoinvalue] = useState("");
    const [isNetwork, setNetwork] = useState(false);
    const [totalsupply, settotalsupply] = useState(0);
    const [contract, setContract] = useState();
    const [cost,setcost] = useState();
    async function getContract() {
        window.web3 = new Web3(window.web3.currentProvider);
        window.ethereum.enable();
        window.web3.eth.defaultAccount = window.web3.currentProvider.selectedAddress;
        
        // var contract = new window.web3.eth.Contract(abi, '0xb30785a108f11d9a21aac5682d4e912769383aa7');
        //var contract = new window.web3.eth.Contract(abi,'0xcedb6a88f8755dec27a05c3726baf0d1e34c84e8');
        // return contract;
        return true;
    }




    useEffect(() => {

if (typeof window.ethereum !== "undefined") {
    isMetamask(true);
           window.ethereum._metamask.isUnlocked().then(status =>{
               if(status){
                
                if (window.ethereum.networkVersion == networkVersion) {
                setNetwork(true);
                getContract().then((cont) => {
                    // setContract(cont);
                    // cont.methods.totalSupply().call().then((res) => {
                    //     settotalsupply(res)
                    // });
                    // cont.methods.cost().call().then((res) => {
                    //     setcost(window.web3.utils.fromWei(res))
                    //     //setcost(0)
                    // });
                })

            }
            }
            else{
                 window.ethereum.request({ method: "eth_requestAccounts" });
                setNetwork(true);
                getContract().then((cont) => {
                    // setContract(cont);
                    // cont.methods.totalSupply().call().then((res) => {
                    //     settotalsupply(res)
                    // });
                    // cont.methods.cost().call().then((res) => {
                    //     setcost(window.web3.utils.fromWei(res))
                    //     //setcost(0)
                    // });
                })
            }
           })
            
            
            
            window.ethereum.on('accountsChanged', function (data){
                if(data.length!=0){
              isMetamask(true);
            }
            
            
            //window.location.reload()
            
        });
            
        } 
 
    }, []);

    const handleChange = (e) => {
        setcoinNumners(e.target.value);
        setcoinvalue(e.target.value * cost);
        //console.log(e.target.value);
        document.getElementById('status').setAttribute('hidden', '');
    };

    // if (metamask) {
        window.ethereum.on('chainChanged', (chainId) => {
            if (window.ethereum.networkVersion == networkVersion) {
                setNetwork(true);

             }
             window.location.reload();
             //else{
            //     setNetwork(false);
            // }
            //window.location.reload();
        });
    //}

    const hasNumber = async () => {
        document.getElementById('status').setAttribute('hidden', '');
        if (coinNumners <= 0) {
            document.getElementById('mintbt').innerText = "PLEASE ENTER A VALID AMOUNT"
            document.getElementById('mintbt').setAttribute('disabled', '');
        } else if (isNaN(coinNumners)) {
            document.getElementById('mintbt').innerText = "PLEASE ENTER A VALID AMOUNT"
            document.getElementById('mintbt').setAttribute('disabled', '');
        } else {
            if(cost==0 && totalsupply<=total_with_free){
                document.getElementById('mintbt').innerText = ` MINT ${coinNumners || 1} Token FOR FREE!!`;
            document.getElementById("mintbt").disabled = false;
            document.getElementById('mintbt').removeAttribute('disabled');

            }else if(cost==0 && totalsupply>total_with_free){
               document.getElementById('mintbt').innerText = "PLEASE CONTACT TO SUPPORT!"
               document.getElementById('mintbt').setAttribute('disabled', '');
            }
            else{
                document.getElementById('mintbt').innerText = ` MINT ${coinNumners || 1} Token FOR = ${coinValue || cost}`;
            document.getElementById("mintbt").disabled = false;
            document.getElementById('mintbt').removeAttribute('disabled');
            }
            

            // 
        }
    }



    const handleMinting = async () => {
      if(isNetwork){
            if (coinNumners == '') {
                document.getElementById('status').innerText = 'PLEASE ENTER A VALID AMOUNT';
                //document.getElementById('status').setAttribute('class', 'btn btn-head');
                document.getElementById('status').removeAttribute('hidden', '');
            }else if(parseInt(totalsupply)+parseInt(coinNumners)>parseInt(total_coins)){
                console.log(parseInt(totalsupply)+parseInt(coinNumners))
                document.getElementById('status').innerText = `Max NFTs limit exceeded.`;
                //document.getElementById('status').setAttribute('class', 'btn btn-head');
                document.getElementById('status').removeAttribute('hidden', '');
            }
             else {
                try {
                    //console.log(contract.methods)
                    contract.methods.isSaleStarted().call().then((status) => {
                        //console.log(status);
                        if (status) {
                            contract.methods.generalSaleTime().call().then((generalsaletime) => {
                                if (Math.round((new Date()).getTime() / 1000) < generalsaletime) {
                                    //console.log("in pre sale")
                                    if (coinNumners > presaleTokenNo) {
                                        document.getElementById('status').innerText = 'Max NFTs per address exceeded for pre-sale.';
                                        //document.getElementById('status').setAttribute('class', 'btn btn-head');
                                        document.getElementById('status').removeAttribute('hidden', '');
                                    } else {

                                        contract.methods.mint(coinNumners).send({ from: window.web3.currentProvider.selectedAddress, value: window.web3.utils.toWei(String(coinValue)) }).then((res) => {
                                            document.getElementById('status').innerText = 'Transaction Successful.';
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                            setTimeout(window.location.reload(),20000)
                                        }).catch((e) => {
                                            console.log(e)
                                            document.getElementById('status').innerText = "Something went wrong!!";
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                        });

                                    }
                                } else {
                                    if(cost==0 && totalsupply<=total_with_free){
                                        console.log("in general sale ,cost is 0")
                                        if (coinNumners > presaleTokenNo) {
                                        document.getElementById('status').innerText = 'Max NFTs per transaction exceeded.';
                                        //document.getElementById('status').setAttribute('class', 'btn btn-head');
                                        document.getElementById('status').removeAttribute('hidden', '');
                                    }else if(cost==0 && totalsupply>total_with_free){
                                        document.getElementById('status').innerText = 'PLEASE CONTACT TO SUPPORT!';
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                    } else {

                                        contract.methods.mint(coinNumners).send({ from: window.web3.currentProvider.selectedAddress, value: window.web3.utils.toWei(String(coinValue)) }).then((res) => {
                                            document.getElementById('status').innerText = 'Transaction Successful.';
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                            setTimeout(window.location.reload(),20000)
                                        }).catch((e) => {
                                            document.getElementById('status').innerText = "Something went wrong!!";
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                        });

                                    }

                                    }else{
                                    console.log("in general sale ,cost is not 0")
                                    if (coinNumners > generalsaleTokenNo) {
                                        document.getElementById('status').innerText = 'Max NFTs per transaction exceeded.';
                                        //document.getElementById('status').setAttribute('class', 'btn btn-head');
                                        document.getElementById('status').removeAttribute('hidden', '');
                                    } else {

                                        contract.methods.mint(coinNumners).send({ from: window.web3.currentProvider.selectedAddress, value: window.web3.utils.toWei(String(coinValue)) }).then((res) => {
                                            document.getElementById('status').innerText = 'Transaction Successful.';
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                            setTimeout(window.location.reload(),20000)
                                        }).catch((e) => {
                                            document.getElementById('status').innerText = "Something went wrong!!";
                                            //document.getElementById('status').setAttribute('class', 'btn mint-btn');
                                            document.getElementById('status').removeAttribute('hidden', '');
                                        });

                                    }

                                }
                            }
                                ////console.log('generalsaletime ', generalsaletime)

                            })

                        } else {
                            document.getElementById('status').innerText = 'Sale is not active this time';
                            //document.getElementById('status').setAttribute('class', 'btn btn-head');
                            document.getElementById('status').removeAttribute('hidden', '');
                        }
                    })







                } catch (e) {
                    document.getElementById('status').innerText = e.message;
                    //document.getElementById('status').setAttribute('class', 'btn btn-head');
                    document.getElementById('status').removeAttribute('hidden', '');
                }
            }




        } else {
            document.getElementById('status').innerText = "Connect to Ethereum Mainnet Network";
            //document.getElementById('status').setAttribute('class', 'btn btn-head');
            document.getElementById('status').removeAttribute('hidden', '');
        }
    }

    return (

        <div className="page-bg">
    <Header metamask={metamask} />
      <section className="sec mint-sec">
    <div className="min-container">
        <h2>Lorem ipsum dolor sit amet. </h2>
     
        <p>Eos laboriosam quaerat ea odit omnis eum beatae rerum qui autem est blanditiis omnis quo accusantium 
            nisi ex excepturi nemo. Sed sunt harum id tempore modi hic omnis atque.</p>
        <div className="mint-form">
         <input id="mint" type="number" name="mint" value={coinNumners} onChange={handleChange} onKeyUp={hasNumber} /> 
            {/*<select>
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
            </select>*/}
            {/*<span className="select-arrow"><img src="assets/images/arrow-down.png" alt="" /></span>*/}
        </div>
        <div className="mint-form">
         <button onClick={handleMinting} className="btn cus-btn" id="mintbt">
              {(cost==0 && totalsupply<=total_with_free?"MINT FOR FREE!!":`MINT ${coinNumners || "1"} TOKEN FOR = ${coinValue || cost }`)}
            </button>
        </div>
        <p><small>{totalsupply} / {total_coins} Minted</small></p>
        
        <p id="status" hidden></p>
    </div>
</section>
<Footer />
     </div>
    );
};

export default MintNFT;