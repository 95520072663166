import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import $ from "jquery";

// <script type="text/javascript">
$(document).ready(function () {
  $('.accordion-list > li > .answer').hide();

  $('.accordion-list > li').click(function () {

    if ($(this).hasClass("active")) {
      $(this).removeClass("active").find(".answer").slideUp();
    } else {
      $(".accordion-list > li.active .answer").slideUp();
      $(".accordion-list > li.active").removeClass("active");
      $(this).addClass("active").find(".answer").slideDown();
    }
    return false;
  });

});

$(document).ready(function () {

  // JQUERY NAV TOGGLE
  $('#menu').bind('click', function (event) {
    $('.top-nav nav').slideToggle();
  });

  $(window).resize(function () {
    var w = $(window).width();
    if (w > 768) {
      $('.top-nav nav').removeAttr('style');
    }
  });

});



function scrollNav() {
  $('.top-nav nav a').click(function () {
    $(".active").removeClass("active");
    $(this).addClass("active");

    $('html, body').stop().animate({
      scrollTop: $($(this).attr('href')).offset().top - 100
    }, 300);
    return false;
  });
}
scrollNav();

$(document).ready(function () {
  $('.join-discord').bind('click', function (event) {
    window.open('https://discord.gg/Rp8UrJEmXY', "_blank");
  });

  $('.opensea-site').bind('click', function (event) {
    window.open('https://pdruppert.elb.cisinlive.com/', "_blank");
  });

});

// </script>

const Home = () => {

  return (
    <>

      <div className="page-bg">
        <Header />
        <section className="sec banner-sec" id="home-sec">
          <div className="container">
            <div className="banner-content">
              <a href="#" className="banner-logo ">
                {/* <img src="assets/images/New-Front-Page-Logo-updated.png" className="project-origin-text-logo" alt="" /> */}
              </a>
              <Link to="/mint" className="btn cus-btn mint-text-btn">
                Mint
              </Link>
              <div className="banner-img">
                <img src="assets/images/banner-img.png" alt="" style={{width:'300px'}}/>
              </div>
            </div>
          </div>
        </section>

        <section className="sec about-sec" id="about-sec">
          <div className="container">
            <div className="row align-items-center">

              <div className="col-md-5">
                <div className="about-img">
                  <img src="assets/images/about-img.png" runat="server" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="about-text">
                  <h2>
                    Lorem ipsum <br />  dolor sit amet
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                    ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                  <a className="btn cus-btn learn-more-text" href="https://discord.gg/Rp8UrJEmXY"
                    target="_blank">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec gallery-sec" id="gallery-sec">
          <div className="container">
            <h2>Gallery</h2>
            <div className="gallery-row">
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/6.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/2.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/6.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/2.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/6.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/2.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/6.png" alt="" />
                </a>
              </div>
              <div className="gallery-col">
                <a className="gallery-img">
                  <img src="assets/images/2.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>



        <section className="sec faq-sec" id="faq-sec">
          <div className="container">
            <h2>FAQ</h2>
            <ul className="accordion-list">
              <li>
                <span className="acc-head">WHAT’S AN NFT?</span>
                <div className="answer">
                  <p>
                    NFT STANDS FOR NON-FUNGIBLE TOKEN. A DIGITAL ASSET THAT IS UNIQUE AND THEREFORE NOT INTERCHANGEABLE.
                  </p>
                </div>
              </li>
              <li>
                <span className="acc-head">
                  WHERE DOES MY NFT GO AFTER I PURCHASE MY ORIGINATOR(s)?
                </span>
                <div className="answer">
                 
                </div>
              </li>
              <li>
                <span className="acc-head">MINT PRICE?</span>
                <div className="answer">
                  <p>0.08 ETH</p>
                </div>
              </li>
              <li>
                <span className="acc-head">WHAT IS THE MINT COUNT?</span>
                <div className="answer">
                  <p>5000</p>
                </div>
              </li>
              <li>
                <span className="acc-head">HOW MANY MINTS PER TRANSACTION?</span>
                <div className="answer">
                  <p>Pre sale (3 per wallet). Public sale (15 per transaction).</p>
                </div>
              </li>
              <li>
                <span className="acc-head">
                  HOW DO I REGISTER FOR THE WHITELIST?
                </span>
                <div className="answer">
                 
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="sec gallery-sec" id="teams">
          <div className="container">
            <div className="our-team">
              <h2>Meet the Team</h2>
              <div className="gallery-row">
                <div className="gallery-col">
                  <div className="gallery-img">
                    <a href="#" target="_blank" className="team-img">
                      <img src="assets/images/BIG-G.png" alt="" />
                    </a>
                    <div className="gal-tit">
                      <a href="#" target="_blank">abc </a>{" "}
                      <a href="#" target="_blank">
                        <img src="assets/images/twitter-icon.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery-col">
                  <div className="gallery-img">
                    <a href="#" target="_blank" className="team-img">
                      <img src="assets/images/H.png" alt="" />
                    </a>
                    <div className="gal-tit">
                      <a href="#" target="_blank">xyz </a>{" "}
                      <a href="#" target="_blank">
                        <img src="assets/images/twitter-icon.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery-col">
                  <div className="gallery-img">
                    <a href="#"
                      target="_blank" className="team-img">
                      <img src="assets/images/BIG-G.png" alt="" />
                    </a>
                    <div className="gal-tit">
                      <a href="#"
                        target="_blank">Abc </a>{" "}
                      <a
                        href="#"
                        target="_blank"
                      >
                        <img src="assets/images/twitter-icon.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery-col">
                  <div className="gallery-img">
                    <a href="#" target="_blank" className="team-img">
                      <img src="assets/images/H.png" alt="" />
                    </a>
                    <div className="gal-tit">
                      <a href="#" target="_blank">ABC </a>{" "}
                      <a href="#" target="_blank">
                        <img src="assets/images/twitter-icon.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="gallery-col">
                  <div className="gallery-img">
                    <a href="#" target="_blank" className="team-img">
                      <img src="assets/images/BIG-G.png" alt="" />
                    </a>
                    <div className="gal-tit">
                      <a href="#" target="_blank">XYZ </a>{" "}
                      <a href="#" target="_blank">
                        <img src="assets/images/twitter-icon.png" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;
