import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
//Components
import Home from "./components/pages/Home";
import Mint from './components/pages/MintNFT';
import Error from "./components/pages/Error";
import Header from "./components/header/Header";

const App = () => {

  return (
    <Router >
      {/* <Header /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/mint" component={Mint} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
};

export default App;
