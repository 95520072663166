import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'

import  App  from "./App";
import store from "./store";

class MyComponenet extends React.Component {
	render(){
		return (
			<Provider store={store}>
        		<App />
    		</Provider>
		);
	}
}

ReactDOM.render(<MyComponenet />,document.getElementById('root'));