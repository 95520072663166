import React from 'react';

const Error = () => {
    return (
        <div>
            <p>Sorry about that! Looks like one of the hamsters in the back fell off the wheel. </p>
        </div>
    );
}

export default Error;